
import { Options, Vue } from 'vue-class-component';

import AppStore from '@/modals/AppStore.vue';
import GooglePlay from '@/modals/GooglePlay.vue';
import ContactUs from '@/modals/ContactUs.vue';
import { mapState } from 'vuex';

@Options({
  components: {
    AppStore,
    GooglePlay,
    ContactUs,
  },
  computed: {
    ...mapState('modals', ['contactUs', 'googlePlay', 'appStore']),
  },
})
export default class GlobalModals extends Vue {

}
