
import { Options, Vue } from 'vue-class-component';

import Modal from '@/common/base/Modal.vue';
import { ToggleModal } from '@/interfaces/store.interface';

import config from '@/config';

@Options({
  components: {
    Modal,
  },
})
export default class ModalAppStore extends Vue {
  public urls = config.store.apple;

  public closeModal(): void {
    this.$store.commit('modals/toggle', { modal: 'appStore', status: false } as ToggleModal);
  }
}
