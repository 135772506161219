
import { Options, Vue } from 'vue-class-component';

import Logo from '@/common/Logo.vue';

@Options({
  components: {
    Logo,
  },
  props: {
    disabledClose: Boolean,
  },
  emits: {
    close: Object,
  },
})
export default class BaseModal extends Vue {
  declare $props: {
    disabledClose: boolean,
  }

  public index = 0;

  public closeModal(): void {
    if (!this.$props.disabledClose && this.index === this.$store.state.countOpenedModal) {
      this.$emit('close');
    }
  }

  created(): void {
    this.$store.commit('changeCountOpenedModal', 1);
    this.index += this.$store.state.countOpenedModal;
  }

  mounted(): void {
    document.documentElement.classList.add('modal-open');
  }

  unmounted(): void {
    this.$store.commit('changeCountOpenedModal', -1);

    if (this.$store.state.countOpenedModal === 0) {
      document.documentElement.classList.remove('modal-open');
    }
  }
}
