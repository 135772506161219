import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "base-element__title"
}
const _hoisted_2 = ["disabled", "placeholder", "value"]
const _hoisted_3 = {
  key: 1,
  class: "base-element__errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-textarea base-element", { 'has-value': _ctx.value_ }])
  }, [
    (_ctx.$props.title || _ctx.$slots.title)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$props.title && !_ctx.$slots.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$props.title), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "title")
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", _mergeProps({ ref: "textarea" }, _ctx.$props.bind, {
      disabled: _ctx.$props.disabled,
      placeholder: _ctx.$props.placeholder,
      value: _ctx.value_,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event.target.value))),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.triggerEmit('focus'))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.triggerEmit('blur')))
    }), null, 16, _hoisted_2),
    (_ctx.$props.errors?.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props?.errors, (msg, id) => {
            return (_openBlock(), _createElementBlock("li", { key: id }, [
              _createElementVNode("span", null, _toDisplayString(msg), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}