import { createApp } from 'vue';

import vClickOutside from 'click-outside-vue3';

import baseInputs from '@/common/base/_global';

import App from './App.vue';

import 'normalize.css';
import '@/style/style.scss';

import router from './router';
import store from './store';

const app = createApp(App);

baseInputs(app);

app
  .use(router)
  .use(store)
  .use(vClickOutside)
  .mount('body');
