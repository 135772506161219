import { createStore } from 'vuex';

import { StoreState } from '@/interfaces/store.interface';

import modals from './modules/modals';

export default createStore<StoreState>({
  state: {
    countOpenedModal: 0,
    isSmartphone: false,
  },
  mutations: {
    changeCountOpenedModal(state, payload: number): void {
      state.countOpenedModal += payload;
    },
    changeIsSmartphone(state, payload: boolean): void {
      state.isSmartphone = payload;
    },
  },
  actions: {
  },
  getters: {
  },
  modules: {
    modals,
  },
});
