import { App } from 'vue';

import BaseInput from '@/common/base/BaseInput.vue';
import BaseTextArea from '@/common/base/BaseTextArea.vue';

function init(app: App<Element>): void {
  app.component('base-input', BaseInput);
  app.component('base-textarea', BaseTextArea);
}

export default init;
