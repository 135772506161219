
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';

@Options({
  props: {
    disabled: Boolean,
    bind: Object,
    errors: Array,
    title: String,
    value: String,
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
  },
  emits: {
    blur: Boolean,
    focus: Boolean,
    change: [String, Number, Object],
    'update:value': [String, Number, Object],
  },
})

/* eslint-disable no-underscore-dangle */

export default class BaseTextArea extends Vue {
  declare $refs: {
    textarea: HTMLTextAreaElement;
  }

  declare $props: {
    disabled: boolean;
    bind: object;
    errors: any[]; // TODO: fix any
    title: string;
    value: string;
    placeholder: string;
  }

  public value_ = '';

  private watchStopHandler!: WatchStopHandle;

  public triggerEmit(trigger: string): void {
    this.$emit(trigger, true);
  }

  public updateValue(v: string): void {
    this.value_ = v;
    this.$emit('change', v || null);
    this.$emit('update:value', v || null);
  }

  created(): void {
    this.watchStopHandler = watch(() => this.$props.value, () => { this.value_ = this.$props.value; });
  }
}
