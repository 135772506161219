import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "base-input base-element" }
const _hoisted_2 = {
  key: 0,
  class: "base-element__title"
}
const _hoisted_3 = ["type", "disabled", "placeholder", "value"]
const _hoisted_4 = {
  key: 0,
  class: "base-element__icon"
}
const _hoisted_5 = {
  key: 1,
  class: "base-element__errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$props.title || _ctx.$slots.title)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$props.title && !_ctx.$slots.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$props.title), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "title")
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["base-input__input", {
        invalid: _ctx.$props?.errors?.length,
        valid: !_ctx.$props?.errors?.length && _ctx.wereErrors && _ctx.value_,
        focus: _ctx.isStatusFocus && (!_ctx.$props?.errors?.length && !_ctx.wereErrors || !_ctx.$props?.errors?.length && !_ctx.value_),
        dirty: !_ctx.isStatusFocus && !_ctx.$props?.errors?.length && !_ctx.wereErrors && _ctx.value_,
        disabled: _ctx.$props.disabled,
        'has-value': _ctx.value_
      }])
    }, [
      _createElementVNode("input", _mergeProps({
        ref: "input",
        id: "input"
      }, _ctx.$props.bind, {
        type: _ctx.type,
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder || _ctx.title,
        value: _ctx.value_,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event.target.value))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleStatusFocus && _ctx.handleStatusFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
      }), null, 16, _hoisted_3),
      (_ctx.$slots.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "icon")
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.$props.errors?.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props?.errors, (msg, id) => {
            return (_openBlock(), _createElementBlock("li", { key: id }, [
              _createElementVNode("span", null, _toDisplayString(msg), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}