import { ModalsState, ToggleModal } from '@/interfaces/store.interface';

type ModalsStateKeys = (keyof ModalsState)[];

const state: ModalsState = {
  contactUs: false,
  googlePlay: false,
  appStore: false,
};

const mutations = {
  toggle(state: ModalsState, payload: ToggleModal): void {
    state[payload.modal] = payload.status;
  },
  reset(state: ModalsState): void {
    const modals: ModalsStateKeys = Object.keys(state) as ModalsStateKeys;
    modals.forEach((v) => { state[v] = false; });
  },
};

const actions = {
  //
};

const getters = {
  //
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
