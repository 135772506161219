const store = {
  google: {
    barber: 'https://play.google.com/store/apps/details?id=com.ibarb.barber',
    client: 'https://play.google.com/store/apps/details?id=com.ibarb.user',
  },
  apple: {
    barber: 'https://itunes.apple.com/app/id1601997804',
    client: 'https://itunes.apple.com/app/id1601998001',
  },
};

export default {
  store,
};
