import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "modal__content" }
const _hoisted_2 = { class: "modal__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    style: _normalizeStyle({ zIndex: 1499 + _ctx.index})
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Logo)
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])), [
      [_directive_click_outside, _ctx.closeModal]
    ])
  ], 4))
}