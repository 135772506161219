
import { Options, Vue } from 'vue-class-component';

import Modal from '@/common/base/Modal.vue';
import { ToggleModal } from '@/interfaces/store.interface';

import * as yup from 'yup';

import axios from 'axios';

@Options({
  components: {
    Modal,
  },
})
export default class ModalContactUs extends Vue {
  public form = {
    name: '',
    email: '',
    msg: '',
  }

  public errors: { [x: string]: string[] } = {
    name: [],
    email: [],
    msg: [],
  }

  private schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    msg: yup.string().required('message is a required field'),
  });

  public send(): void {
    this.schema.validate(this.form, { abortEarly: false })
      .then(() => {
        axios.post('https://ibarb.app/accounts/contact-us', this.form)
          .then(() => { this.closeModal(); });
      })
      .catch((err: yup.ValidationError) => {
        err.inner.forEach((e) => {
          if (e.path && this.errors[e.path]) {
            this.errors[e.path as string].push(e.message);
          }
        });
      });
  }

  public closeModal(): void {
    this.$store.commit('modals/toggle', { modal: 'contactUs', status: false } as ToggleModal);
  }
}
