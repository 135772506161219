
import { Options, Vue } from 'vue-class-component';

import GlobalModals from '@/common/GlobalModals.vue';

@Options({
  components: {
    GlobalModals,
  },
})
export default class App extends Vue {
  private updateWidth() {
    if (window.innerWidth <= 1160) {
      this.$store.commit('changeIsSmartphone', true);
    } else {
      this.$store.commit('changeIsSmartphone', false);
    }
  }

  created(): void {
    this.updateWidth();

    window.addEventListener('resize', this.updateWidth);
  }

  unmounted(): void {
    window.removeEventListener('resize', this.updateWidth);
  }
}
